import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
              <h1>Welcome to Mattaponetic!</h1>
              
        <p>
          Coming soon
        </p>
        
      </header>
    </div>
  );
}

export default App;
